<template>
    <div class="footer">
        <div class="footer-logo">
            <img src="../assets/Home/lgo.png" alt="">
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .footer {
        padding:30px;
        background-color:#041019;
        text-align:center;
    }

    .footer-powered {
        margin:auto 0;
    }

    @media screen and (max-width:450px) {
        .footer {
            text-align:center;
        }

        .footer-logo, .footer-powered {
            margin:0 auto;
        }
    }
</style>