<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
  @font-face {
    font-family: 'Poppins';
    src: url('./assets/Fonts/Poppins-Regular.ttf');
    /* Add additional src declarations for different formats if necessary */
  }

  @font-face {
    font-family: 'Poppins-Bold';
    src: url('./assets/Fonts/Poppins-Bold.ttf');
    /* Add additional src declarations for different formats if necessary */
  }

  body {
    margin:0;
    font-family: 'Poppins', sans-serif; /* Use the imported font */
    background:linear-gradient(to bottom, #062a42, #041d2d);
  }
</style>
