<template>
  <div class="home-view">
    <NavBar  :active="'home'" />
    <div class="home-content">
        <div class="hero">
            <div class="hero-text">
                <div class="hero-title">
                    PROTECT THE
                    MOST IMPORTANT
                    ASSET OF ALL: <span style="color:#0b8fd8;">YOU</span>
                </div>
                <div class="hero-subtext">
                    IDChain enables businesses to protect themselves and the identities of their
                    users through blockchain-backed identification and know-your-customer (KYC).
                </div>
                <div class="btn-row">
                    <button class="btn-signup">Sign Up</button>
                    <button class="btn-login">Login</button>
                </div>
            </div>
            <div class="hero-img">
                <img src="../assets/Home/Asset 25.png" alt="">
            </div>
        </div>
        <div class="problems">
            <div class="problems-title">
                People are turning more and more of
                their lives online, which creates a wide variety
                of problems for both businesses and users.
            </div>
            <div class="problems-row">
                <div class="problems-card">
                    <div class="card-text">
                        <div class="card-title">
                            Identity theft
                        </div>
                        <div class="card-subtext">
                            Hackers can steal the
                            identification of
                            legitimate people.
                        </div>
                    </div>
                    <div class="card-img">
                        <img src="../assets/Home/icon1.png" alt="">
                    </div>
                </div>
                <div class="problems-card">
                    <div class="card-text">
                        <div class="card-title">
                            Fake identity use
                        </div>
                        <div class="card-subtext">
                            When KYC procedures
                            are weak, bad actors can
                            use stolen or fabricated
                            identities. 
                        </div>
                    </div>
                    <div class="card-img">
                        <img src="../assets/Home/icon2.png" alt="">
                    </div>
                </div>
                <div class="problems-card">
                    <div class="card-text">
                        <div class="card-title">
                            Fraudulent activity
                        </div>
                        <div class="card-subtext">
                            When bad actors slip into
                            systems with fake IDs, they
                            can then commit fraudulent
                            activity with little fear of
                            repercussion.
                        </div>
                    </div>
                    <div class="card-img">
                        <img src="../assets/Home/icon3.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="top-borders">
            <div class="border"></div>
            <div class="border"></div>
        </div>
        <div class="protects">
            <div class="protects-text">
                <div class="protects-title">
                    IDChain protects
                    your identity online
                    and offline.
                </div>
                <div class="protects-subtext">
                    IDChain enables users to verify their identity, put their critical data assets
                    on the blockchain, and then submit this information on an as-needed basis
                    to different institutions, who can now verify this information with confidence.
                </div>
                <div class="protects-subtext">
                    The critical data assets supported by IDChain include:
                </div>
            </div>
            <div class="protects-right">
                <div class="protects-grid">
                    <div class="protects-img">
                        <img src="../assets/Home/Asset 5.png" alt="">
                    </div>
                    <div class="protects-img">
                        <img src="../assets/Home/Asset 6.png" alt="">
                    </div>
                    <div class="protects-img">
                        <img src="../assets/Home/Asset 7.png" alt="">
                    </div>
                    <div class="protects-img">
                        <img src="../assets/Home/Asset 11.png" alt="">
                    </div>
                    <div class="protects-img">
                        <img src="../assets/Home/Asset 12.png" alt="">
                    </div>
                    <div class="protects-img">
                        <img src="../assets/Home/Asset 13.png" alt="">
                    </div>
                    <div class="protects-img">
                        <img src="../assets/Home/Asset 14.png" alt="">
                    </div>
                    <div class="protects-img">
                        <img src="../assets/Home/Asset 15.png" alt="">
                    </div>
                    <div class="protects-img">
                        <img src="../assets/Home/Asset 16.png" alt="">
                    </div>
                    <div class="protects-img">
                        <img src="../assets/Home/Asset 26.png" alt="">
                    </div>
                </div>
                <div>And more...</div>
            </div>
        </div>
        <div class="problems">
            <div class="problems-row">
                <div class="problems-card">
                    <div class="card-text">
                        <div class="card-title">
                            Protect your identity
                        </div>
                        <div class="card-subtext">
                            When individuals back
                            their identity onto the
                            blockchain, they can
                            ensure that their critical
                            data assets will never
                            be stolen.
                        </div>
                    </div>
                    <div class="card-img">
                        <img src="../assets/Home/icon4.png" alt="">
                    </div>
                </div>
                <div class="problems-card">
                    <div class="card-text">
                        <div class="card-title">
                            Save money
                        </div>
                        <div class="card-subtext">
                            Individuals and businesses
                            experience significant financial
                            losses due to crimes and
                            activity associated with
                            identity theft. 
                        </div>
                    </div>
                    <div class="card-img">
                        <img src="../assets/Home/icon5.png" alt="">
                    </div>
                </div>
                <div class="problems-card">
                    <div class="card-text">
                        <div class="card-title">
                            Reduce operational costs
                        </div>
                        <div class="card-subtext">
                            Dealing with identity theft
                            and associated crimes is
                            resource-intensive for
                            businesses. With IDChain,
                            enterprises no longer need
                            to make such.
                        </div>
                    </div>
                    <div class="card-img">
                        <img src="../assets/Home/icon6.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="signup">
            <div class="signup-text">
                <div class="signup-title">
                    Back your identity
                    with the blockchain.
                </div>
                <div class="signup-subtext">
                    Sign up for IDChain today.
                    <button class="btn-signup">Sign Up</button>
                </div>
            </div>
        </div>
    </div>
    <FooterComponent  :active="'home'" />
  </div>
</template>

<script>
import NavBar from '../components/NavBar.vue'; // Import the Navbar component
import FooterComponent from '../components/FooterComponent.vue';

export default {
  components: {
    NavBar,
    FooterComponent // Register the Navbar component
  },
  // Other component options
}
</script>

<style scoped>
    .home-view {
        background:url(../assets/Home/bg1.jpg);
        color:white;
        background-size:cover;
        background-repeat:no-repeat;
    }

    /* Hero */

    .hero {
        display:flex;
        flex-wrap:wrap;
        justify-content:space-around;
    }

    .hero-text {
        width:600px;
        margin:auto 0;
    }

    .hero-title {
        font-family:'Poppins-Bold';
        font-size:50px;
    }

    .hero-subtext {
        margin-top:1em;
        margin-bottom:1.5em;
    }

    .hero-img {
        width:650px;
    }

    .hero-img img {
        width:100%;
    }

    .btn-signup,
    .btn-login {
        background-color: #4CAF50;
        border: none;
        color: white;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 5px;
        width:200px;
    }

    .btn-signup {
        background-color: transparent;
        border: none;
        color: white;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        margin-right:10px;
        cursor: pointer;
        /* Gradient Border */
        border: 2px solid transparent;
        border-color: #a268aa;
        border-image-slice: 1;
        border-radius: 5px; /* Apply border-radius */
    }

    .btn-login {
        background: #0d5e94;
        border: 2px #0d5e94 solid;
        color: white;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 5px;
    }

    /* Problems */

    .problems {
        text-align:center;
        padding:30px;
    }

    .problems-title {
        font-family:'Poppins-Bold';
        font-size:30px;
        width:70%;
        margin:0 auto;
    }

    .problems-row {
        margin:30px;
        display:flex;
        flex-wrap:wrap;
        justify-content:space-around;
    }

    .problems-card {
        background-image:url(../assets/Home/Asset\ 18.png);
        background-size:cover;
        padding:20px;
        width:380px;
        height:180px;
        text-align:left;
        display:flex;
        flex-wrap:wrap;
        justify-content:space-around;
        border-radius:10px;
        border-bottom:#0d5e94 2px solid;
    }

    .card-text {
        width:200px;
        margin:auto 0;
    }

    .card-title {
        margin-bottom:5px;
        font-family:'Poppins-Bold';
        color:#0b8fd8;
        font-size:15px;
    }

    .card-subtext {
        font-size:12px;
    }

    .card-img {
        width:0px;
        margin:auto 0;
        display:none;
    }

    .card-img img {
        width:100%;
    }

    .top-borders {
        justify-content:space-between;
        display:flex;
    }

    .border {
        height:2px;
        width:200px;
        background-color:white;
    }

    .protects {
        padding:30px;
        display:flex;
        flex-wrap:wrap;
        justify-content: space-around;

    }

    .protects-text {
        width:500px;
        margin:auto 0;
    }

    .protects-title {
        font-size:50px;
        font-family:'Poppins-Bold';
    }

    .protects-right {
        text-align: center;
    }

    .protects-grid {
        display:flex;
        flex-wrap:wrap;
        justify-content:space-around;
        width:600px;
    }

    .protects-img {
        margin:15px 0px;
    }

    .signup {
        padding:80px 80px;
        text-align:center;
    }

    .signup-img {
        width:100%;
        height:auto;
    }

    .signup {
        padding:40px;
    }

    .signup-text {
        width:80%;
        margin:0 auto;
    }

    .signup-title {
        font-family:'Poppins-Bold';
        font-size:50px;
    }

    .signup-subtext {
        margin-top:30px;
    }

    @media screen and (max-width:1024px) {
        .hero {
            margin-top:30px;
        }
        .hero-text, .protects-text {
            width:70%;
            margin:0 auto;
            text-align:center;
            margin-bottom:30px;
        }

        .problems-card {
            margin-bottom:20px;
            width:80%;
            height:auto;
            justify-content:space-between;
            padding:30px;
        }

        .card-text {
            width:60%;
        }

        .card-title {
            font-size:30px;
        }

        .card-subtext {
            font-size:20px;
        }

        .card-img {
            width:150px;
        }

        .protects-grid {
            width:70%;
            margin:0 auto;
        }

        .signup-text {
            width:90%;
        }
    }

    @media screen and (max-width:450px) {
        .problems-title {
            width:100%;
        }
        .protects-text, .problems-text {
            width:100%;
        }
        .protects-title {
            font-size:35px;
        }
        .protects-img img {
            width:100%;
        }
        .signup-title {
            font-size:30px;
        }

        .problems-card {
            text-align:center;
            border-radius:30px;
        }

        .card-text {
            width:100%;
        }

        .card-img {
            width:80%;
            margin:0 auto;
            margin-top:50px;
        }

        .protects-img {
            width:100%;
        }
    }
</style>