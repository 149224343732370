<template>
    <div class="about-view">
        <NavBar :active="'about'" />
        <div class="hero">
            <div class="hero-img">
                <img src="../assets/About/Asset 29.png" alt="">
            </div>
            <div class="hero-title">
                ABOUT IDCHAIN
            </div>
            <div class="hero-subtext">
                Welcome to IDChain.com, your premier blockchain-based KYC ID platform. We pride ourselves on revolutionizing 
                identity verification by harnessing the power of blockchain technology. Our platform facilitates secure, 
                transparent, and efficient verifications of individuals, backed by the trust of other individuals, companies, 
                and government institutions. At IDChain.com, we believe in empowering individuals to take control of their 
                digital identities while ensuring compliance and security are paramount. Join us in shaping the future of 
                identity verification on the blockchain.
            </div>
        </div>
        <div class="mission">
            <div class="mission-img">
                <img src="../assets/About/mission.png" alt="">
            </div>
            <div class="mission-text">
                <div class="mission-title">Mission</div>
                <div class="mission-subtext">
                    IDChain will enable people to live their
                    best lives, online and offline, with full
                    confidence in their security.
                </div>
            </div>
        </div>
        <div class="vision">
            <div class="vision-text">
                <div class="vision-title">Vision</div>
                <div class="vision-subtext">
                    IDChain will eliminate identity theft in its
                    entirety, so that the crimes previously
                    associated with them will disappear as well. 
                </div>
            </div>
            <div class="vision-img">
                <img src="../assets/About/vision.png" alt="">
            </div>
        </div>
        <div class="whitepaper">
            <div class="whitepaper-title">
                Revolutionizing Identity Verification with Blockchain
            </div>
            <div class="whitepaper-text">
                IDChain aims to stands at the forefront of innovation in identity verification, leveraging the 
                transformative potential of blockchain technology to address the rampant challenges posed by identity 
                theft and fraud. By harnessing the power of the Solana blockchain, augmented with Artificial Intelligence 
                (AI) with value added services, IDChain offers a robust solution that ensures the integrity and security of 
                identity verification processes. The platform provides a secure and transparent ecosystem where individuals, 
                businesses, and government agencies can seamlessly verify identities, mitigating risks associated with 
                fraudulent activities.
            </div>
            <div class="whitepaper-text">
                With its foundation on the Solana blockchain, IDChain provides a decentralized infrastructure that enhances 
                trust and transparency in identity verification. Through the integration of AI, the platform offers advanced 
                capabilities such as facial recognition and verification, further bolstering the accuracy and reliability of 
                identity verification processes. This whitepaper serves as a comprehensive guide to the features and benefits 
                of IDChain, highlighting its role in addressing the pressing need for dependable identity verification solutions 
                in today's digital landscape. By empowering users with control over their digital identities while ensuring 
                compliance and security, IDChain is poised to revolutionize the way identities are verified and authenticated 
                across diverse sectors.
            </div>
        </div>
        <div class="help">
            <div class="help-img">
                <img src="../assets/About/asset-06.png" alt="">
            </div>
            <div class="help-text">
                <div class="help-title">We're here to help.</div>
                <div class="help-subtext">
                    As an identification and KYC company, we take pride
                    in maintaining a 24-7 storefront. Please reach out
                    to us anytime via these channels: 
                </div>
                <div class="help-contact">
                    <div class="contact-row">
                        <div class="contact-img">
                            <img src="../assets/About/email.png" alt="">
                        </div>
                        <div class="contact-text">hello@idchain.com</div>
                    </div>
                    <div class="contact-row">
                        <div class="contact-img">
                            <img src="../assets/About/Asset 31.png" alt="">
                        </div>
                        <div class="contact-text">@IDCHAIN.com</div>
                    </div>
                    <div class="contact-row">
                        <div class="contact-img">
                            <img src="../assets/About/telegram.png" alt="">
                        </div>
                        <div class="contact-text">@IDCHAIN.com</div>
                    </div>
                </div>
                <div class="help-subtext">
                    We aim to respond within 10 minutes, but response time may vary depending on customer service volume.
                </div>
            </div>
        </div>
        <FooterComponent />
    </div>
</template>

<script>
import NavBar from '../components/NavBar.vue'; // Import the Navbar component
import FooterComponent from '../components/FooterComponent.vue';

export default {
  components: {
    NavBar,
    FooterComponent // Register the Navbar component
  },
  // Other component options
}
</script>

<style scoped>
    .about-view {
        background:url(../assets/About/bg2.jpg);
        background-position-x:center;
        color:white;
        background-size:cover;
        background-repeat:no-repeat;
    }

    .hero {
        padding:30px;
        text-align:center;
    }

    .hero-title {
        margin:30px 0;
        font-size:60px;
        font-family:Poppins-Bold;
    }

    .hero-img {
            margin:0 auto;
        }

        .hero-img img {
            width:400px;
        }

    .hero-subtext {
        width:60%;
        margin:0 auto;
    }

    .mission, .vision {
        display:flex;
        flex-wrap:wrap;
        justify-content:center;
        padding:40px;
    }

    .mission-img, .vision-img {
        margin-right:20px;
        width:400px;
        text-align:center;
    }

    .mission-text, .vision-text {
        margin:auto 0;
        width:400px;
    }

    .mission-title, .vision-title {
        font-size:40px;
        margin-bottom:10px;
        font-family:'Poppins-Bold';
        color:#0b8fd8;
    }

    .whitepaper {
        padding:30px;
        width:70%;
        margin:0 auto;
    }

    .whitepaper-title {
        text-align:center;
        font-size:40px;
        font-family:Poppins-Bold;
    }

    .whitepaper-text {
        margin-top:10px;
    }

    .whitepaper-text strong {
        margin-top:20px;
        font-family:Poppins-Bold;
    }

    .help {
        padding:50px 30px;
        display:flex;
        flex-wrap:wrap;
        justify-content: space-around;
    }

    .help-img {
        width:600px;
    }

    .help-img img {
        width:100%;
        object-fit:contain;
    }

    .help-text {
        width:550px;
        margin:auto 0;
    }

    .help-title {
        font-size:40px;
        font-family:'Poppins-Bold';
    }

    .help-contact {
        margin:30px 0px;
    }

    .contact-row {
        display:flex;
        margin:15px 0px;
    }


    .contact-img {
        width:30px;
        margin:auto 0;
    }

    .contact-img img {
        width:100%;
        height:auto;
    }

    .contact-text {
        margin-left:10px;
    }

    .hero-img {
            width:70%;
            margin:0 auto;
        }

    @media screen and (max-width:1024px) {
        .hero-img {
            width:70%;
            margin:0 auto;
        }

        .hero-img img {
            width:100%;
            height:auto;
        }
        
        .help-img {
            display:none;
        }

        .help-text {
            width:80%;
            text-align:center;
        }

        .hero-subtext {
            width:80%;
        }
    }
</style>