<template>
  <div class="partner-view">
    <NavBar :active="'our-partner'"/>
    <div class="partner-img">
      <img src="../assets/Our Partner/blx.jpg" alt="">
    </div>
    <div class="partner-about">
      <div class="about-title">
        IDChain’s Blockchain Backbone is Solana
      </div>
      <div class="about-subtext">
        IDChain utilizes the Solana blockchain for its robust and reliable network capabilities, which are essential for 
        ensuring high performance and security in identity verification processes. Solana's advanced technology, known for 
        its high throughput and low latency, enables IDChain to handle large volumes of transactions efficiently. This 
        scalability is crucial for supporting a growing number of users and institutions, ensuring that the platform 
        remains responsive and reliable as it expands its global reach.
      </div>
      <div class="about-subtext">
        The decision to integrate Solana into IDChain's infrastructure is driven by the blockchain's proven reliability and 
        resilience. Solana's unique consensus mechanism, combining Proof of History (PoH) with Proof of Stake (PoS), provides 
        a secure and decentralized environment that is resistant to attacks and fraud. This robust security framework is 
        essential for safeguarding sensitive identity information and maintaining trust in the verification process, making 
        Solana an ideal choice for IDChain's mission to enhance digital identity verification.
      </div>
      <div class="about-subtext">
        Furthermore, Solana's ecosystem offers seamless interoperability with existing blockchain technologies, allowing IDChain 
        to integrate smoothly with other systems and platforms. This compatibility enhances IDChain's ability to collaborate with 
        industry partners, regulatory bodies, and technology experts, facilitating a more connected and efficient digital identity
         verification landscape. By leveraging Solana's strengths, IDChain is well-positioned to drive innovation and transformation 
         in the field of identity verification.
      </div>
    </div>
    <div class="partner-signup">
      <div class="signup-img">
        <img src="../assets/Our Partner/Asset 44.png" alt="">
      </div>
      <div class="signup-text">
        <div class="signup-title">
          Put your identity on
          the blockchain today.
        </div>
        <div class="signup-subtet">
          Sign up for IDChain today.
        </div>
        <button class="btn-signup">SIGN UP</button>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import NavBar from '../components/NavBar.vue'; // Import the Navbar component
import FooterComponent from '../components/FooterComponent.vue';

export default {
  components: {
    NavBar,
    FooterComponent // Register the Navbar component
  },
  // Other component options
}
</script>

<style scoped>
 .partner-view {
    background:url(../assets/Our\ Partner/bg5.jpg);
    background-position-x:center;
    color:white;
    min-height:100vh;
    background-size:cover;
  }

  .partner-img img {
    width:100%;
  }

  .partner-about {
    padding:30px 60px;
    text-align:center;
  }

  .about-title {
    font-size:40px;
    font-family:'Poppins-Bold';
  }

  .about-subtext {
    width:60%;
    margin:0 auto;
    margin-top:20px;
  }

  .partner-signup {
    display:flex;
    flex-wrap:wrap;
    justify-content:space-around;
    padding:30px;
  }

  .signup-img {
    width:500px;
  }

  .signup-img img {
    width:100%;
  }

  .signup-text {
    margin:auto 0;
    width:500px;
  }

  .signup-title {
    font-size:40px;
    font-family:'Poppins-Bold';
  }

  .btn-signup,
  .btn-login {
      background-color: #4CAF50;
      border: none;
      color: white;
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
      border-radius: 5px;
      width:200px;
  }

  .btn-signup {
      background-color: transparent;
      border: none;
      color: white;
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px 2px;
      margin-right:10px;
      cursor: pointer;
      /* Gradient Border */
      border: 2px solid transparent;
      border-color: #a268aa;
      border-image-slice: 1;
      border-radius: 5px; /* Apply border-radius */
  }

  @media screen and (max-width:1024px) {
    .about-subtext {
      width:100%;
    }

    .signup-img {
      display:none;
    }

    .signup-text {
      width:100%;
      text-align:center;
    }

    .btn-signup {
      margin-top:20px;
    }
  }

  @media screen and (max-width:450px) {
      .signup-title, .about-title {
        font-size:30px;
      }
  }
</style>