<template>
  <div class="press-view">
    <NavBar :active="'press'"/>
    <div class="press-content">
      <div class="press-title">AS FEATURED IN</div>
      <div class="press-subtext">
          Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
          Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
      </div>
      <div class="press-row">
        <!-- <div class="press-card">
          <div class="press-img"></div>
          <div class="press-subtext">
            Fintech firm selects
            BLX blockchain
          </div>
          <div class="press-readmore">Read More</div>
        </div> -->
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import NavBar from '../components/NavBar.vue'; // Import the Navbar component
import FooterComponent from '../components/FooterComponent.vue';

export default {
  components: {
    NavBar,
    FooterComponent // Register the Navbar component
  },
  // Other component options
}
</script>

<style scoped>
 .press-view {
    background:url(../assets/Press/bg4.jpg);
    background-position-x:center;
    color:white;
    min-height:100vh;
    background-size:cover;
  }

  .press-content {
    padding:30px;
    text-align:center;
  }

  .press-title {
    margin:30px 0;
    font-family:'Poppins-Bold';
    font-size:50px;
  }
  
  .press-subtext {
    width:60%;
    margin:0 auto;
  }
</style>