import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/HomeView.vue';
import About from './views/AboutView.vue';
import Press from './views/PressView.vue';
import HowItWorks from './views/HowItWorksView.vue';
import OurPartner from './views/OurPartnerView.vue';

const routes = [
  { path: '/', component: Home },
  { path: '/about', component: About },
  { path: '/press', component: Press },
  { path: '/how-it-works', component: HowItWorks },
  { path: '/our-partner', component: OurPartner }
  // Add more routes here
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;