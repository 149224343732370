<template>
    <nav class="navbar">
      <div class="navbar-left">
        <!-- Logo -->
        <img src="../assets/Home/lgo.png" alt="Logo" class="logo">
      </div>
      <div class="navbar-center">
        <!-- Links -->
        <ul class="nav-links">
          <li :class="{active: active == 'home'}"><a href="/">Home</a></li>
          <li :class="{active: active == 'about'}"><a href="/about">About</a></li>
          <!-- <li :class="{active: active == 'press'}"><a href="/#/press">Press</a></li> -->
          <li :class="{active: active == 'how-it-works'}"><a href="/how-it-works">How It Works</a></li>
          <li :class="{active: active == 'our-partner'}"><a href="/our-partner">Our Partner</a></li>
          <li><a href="/IDChain_Whitepaper.pdf" target="_blank">Whitepaper</a></li>
        </ul>
      </div>
      <div class="navbar-right">
        <!-- Signup and Login Buttons -->
        <button class="btn-signup">Signup</button>
        <button class="btn-login">Login</button>
      </div>
    </nav>
</template>

<script>
export default {
    props: ["active"]
}
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background:none;
  color: white;
}

.navbar-left {
  flex: 1;
}

.navbar-center {
  flex: 2;
  text-align: center;
}

.nav-links {
  list-style-type: none;
  padding: 0;
}

.nav-links li {
  display: inline;
  margin: 0 10px;
}

.nav-links li a {
  color: white;
  text-decoration: none;
}

.nav-links li::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: linear-gradient(to top, rgba(0,30,46,0.5), transparent);
  z-index: -1;
  transition: height 0.3s;
}

.nav-links li a:hover::before {
  height: 100%;
}

.nav-links li a.active::before {
  height: 100%;
}

.nav-links .active {
  border-bottom: 2px solid #0d5e94;
}


.navbar-right {
  flex: 1;
  text-align: right;
}

.btn-signup,
.btn-login {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}

.btn-signup {
  background-color: transparent;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  /* Gradient Border */
  border: 2px solid transparent;
  border-color: #a268aa;
  border-image-slice: 1;
  border-radius: 5px; /* Apply border-radius */
}

.btn-login {
  background: #0d5e94;
  border: 2px #0d5e94 solid;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}

.logo {
  max-width: 200px; /* Adjust as needed */
  height: auto;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: stretch;
  }

  .navbar-left,
  .navbar-center,
  .navbar-right {
    width: 100%;
    text-align: center;
  }

  .navbar-right {
    margin-top: 10px;
  }
}
</style>